import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import style from "./HkNews.module.css";
import Loading from "../Components/Loading";
import InfiniteLoading from "../Utils/InfiniteLoading";

export default function HkNews() {

    const [articles, setArticles] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [nextPage, setNextPage] = useState(0);
    const [isEndOfPage, setIsEndOfPage] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [toTop, setToTop] = useState(false);
    const [previousScrollPos, setPreviousScrollPos] = useState(0);

    const getData = async (page) => {
        const response = await fetch(`http://140.238.197.242:5002/api/news/hk?page=${page}`);
        const data = await response.json();

        if(response.ok){
            setNextPage(data.nextPage)

            setArticles(prevContent => {
                if(page === 0){
                    return data.data;
                }
                else{
                    return [...prevContent, ...data.data];
                };
            });
        };
    };

    useEffect(() => {
        setTimeout(() => {
            getData(currentPage);
            setIsLoading(false);
        }, 0);

        setIsLoading(true);
    }, [currentPage]);

    useEffect(() => {
        if(isEndOfPage){
            if(nextPage != null){
                setCurrentPage(nextPage);
                setIsEndOfPage(false);
            };
        };
    }, [isEndOfPage, nextPage]);

    const handleToTopBtnClick = () => {
        window.scrollTo({top: 0, behavior: "smooth"});
    };

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset || document.documentElement.scrollTop;
        
            if(currentScrollPos < previousScrollPos){
                setToTop(true);
            };

            if(currentScrollPos > previousScrollPos || currentScrollPos === 0){
                setToTop(false);
            };
        
            setPreviousScrollPos(currentScrollPos);
        };
        
        window.addEventListener("scroll", handleScroll);
    
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [previousScrollPos]);

    return (
        <>
            <div className = { style.hk_news_center }>
                <div className = { style.hk_news_container }>
                    <div className = { style.hk_news_photo_outer_container }>
                        {
                            articles &&
                            articles.map(news => (
                                <div className = { style.hk_news_photo_container } key = { news.id }>
                                    <Link to = { "/" + news.id }>
                                        <img className = { style.hk_news_photo } src = { news.photo } alt = "" />
                                    </Link>
                                    <div className = { style.hk_news_title }>{ news.title }</div>
                                    <div className = { style.hk_news_date }>{ news.date }</div>
                                </div>
                            ))
                        }

                        { <Loading isLoading = { isLoading } /> }

                        { <InfiniteLoading setIsEndOfPage = { setIsEndOfPage } /> }
                    </div>
                </div>
            </div>

            {
                toTop &&
                <img
                    className = { style.to_top }
                    src = { process.env.PUBLIC_URL + "top.png" }
                    alt = ""
                    onClick = { handleToTopBtnClick }
                />
            }
        </>
    )

};