import React from "react";
import { Link } from "react-router-dom";
import style from "./NavBar.module.css";

export default function NavBar() {

    return (
        <>
            <div className = { style.nav_bar_center }>

                <div className = { style.nav_bar_container }>

                    <div className = { style.nav_bar_website_title }>
                        <Link to = "/">News App</Link>
                    </div>

                    <div className = { style.nav_bar_news_container }>
                        {/* <Link to = "/">
                            <span className = { style.nav_bar_news_title }>JP News</span>
                        </Link> */}
                        
                        {/* <Link to = "/hk">
                            <span className = { style.nav_bar_news_title }>HK News</span>
                        </Link> */}
                    </div>

                </div>

            </div>
        </>
    )

};