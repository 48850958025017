import React from "react";
import style from "./Loading.module.css";

export default function Loading({ isLoading }) {

    return (
        <>
            {
                isLoading &&
                <div className = { style.loading }>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            }
        </>
    )

};