import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavBar from "./Components/NavBar";
import JapaneseNews from "./Pages/JapaneseNews";
import HkNews from "./Pages/HkNews";
import HkNewsIndividual from "./Pages/HkNewsIndividual";
import JpNewsIndividul from "./Pages/JpNewsIndividual";

function App() {

  return (

    <div>
      
      <BrowserRouter>

        <NavBar />

        <Routes>

          {/* <Route path = "/" element = { <JapaneseNews /> } />

          <Route path = "/" element = { <JpNewsIndividul /> }>
            <Route path = ":id" element = { <JpNewsIndividul /> } />
          </Route> */}

          <Route path = "/" element = { <HkNews /> } />

          <Route path = "/" element = { <HkNewsIndividual /> }>
            <Route path = ":id" element = { <HkNewsIndividual /> } />
          </Route>

          <Route path = "*" element = { <p>找不到頁面</p> }/>

        </Routes>

      </BrowserRouter>

    </div>

  );

}

export default App;