// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HkNewsIndividual_center__buLkK {\n    display: flex;\n    justify-content: center;\n    align-items: center;  \n}\n\n.HkNewsIndividual_container__zol2Y {\n    width: 1200px;\n    margin: 10px;\n}\n\n.HkNewsIndividual_photo__4sAyb {\n    width: 96%;\n    margin-top: 15px;\n    border-radius: 10px;\n}\n\n.HkNewsIndividual_content__aTZ7l {\n    width: 96%;\n    margin-top: 15px;\n    font-size: 20px;\n}\n\n.HkNewsIndividual_home_button__KQZXb {\n    width: 100px;\n    margin: 20px;\n    padding: 8px;\n    border-radius: 5px;\n    text-align: center;\n    cursor: pointer;\n    color: #000;\n    background-color: #fff;\n}\n\n.HkNewsIndividual_home_button__KQZXb:hover {\n    opacity: 80%;\n}", "",{"version":3,"sources":["webpack://./src/Pages/HkNewsIndividual.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,sBAAsB;AAC1B;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".center {\n    display: flex;\n    justify-content: center;\n    align-items: center;  \n}\n\n.container {\n    width: 1200px;\n    margin: 10px;\n}\n\n.photo {\n    width: 96%;\n    margin-top: 15px;\n    border-radius: 10px;\n}\n\n.content {\n    width: 96%;\n    margin-top: 15px;\n    font-size: 20px;\n}\n\n.home_button {\n    width: 100px;\n    margin: 20px;\n    padding: 8px;\n    border-radius: 5px;\n    text-align: center;\n    cursor: pointer;\n    color: #000;\n    background-color: #fff;\n}\n\n.home_button:hover {\n    opacity: 80%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"center": "HkNewsIndividual_center__buLkK",
	"container": "HkNewsIndividual_container__zol2Y",
	"photo": "HkNewsIndividual_photo__4sAyb",
	"content": "HkNewsIndividual_content__aTZ7l",
	"home_button": "HkNewsIndividual_home_button__KQZXb"
};
export default ___CSS_LOADER_EXPORT___;
