import React, { useRef, useEffect } from "react";

export default function InfiniteLoading({ setIsEndOfPage }) {

    const bottomMarkerRef = useRef(null);

    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if(entry.isIntersecting){
                setIsEndOfPage(true);
            };
        });
    });

    useEffect(() => {
        observer.observe(bottomMarkerRef.current);
        
        return () => observer.disconnect();
    }, []);

    return (
        <>
            <div ref = { bottomMarkerRef }></div>
        </>
    )

};