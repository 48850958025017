import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import style from "./HkNewsIndividual.module.css";

export default function HkNewsIndividual() {

    const params = useParams();
    const [articles, setArticles] = useState([]);
    const [displayButton, setDisplayButton] = useState(false);

    useEffect(() => {
        const getData = async () => {
            const response = await fetch(`http://140.238.197.242:5002/api/news/hk/${params.id}`);
            const data = await response.json();

            if(response.ok){
                setArticles(data.data);
                setDisplayButton(true);
            };
        };

        getData();
    }, [params.id]);

    return (
        <>
            {
                <div className = { style.center }>
                    <div className = { style.container }>
                        <div className = { style.center }>
                            <img className = { style.photo } src = { articles.photo } alt = "" />
                        </div>

                        <div className = { style.center }>
                            <div className = { style.content }>{ articles.article }</div>
                        </div>

                        {
                            displayButton &&
                            <div className = { style.center }>
                                <Link to = "/">
                                    <div className = { style.home_button }>Back</div>
                                </Link>
                            </div>
                        }
                    </div>
                </div>
            }   
        </>
    )

};